import { useLocation } from '@reach/router'
import { type GatsbySSR } from 'gatsby'
import React, { type FC, useEffect } from 'react'

const AddCalculetteConsoScript: FC<{
  version: string
  pages: string[]
}> = ({ version = 'v2', pages = [] }) => {
  const location = useLocation()

  useEffect(() => {
    if (pages.includes(location.pathname)) {
      const calculetteConsoScript = document.createElement('script')
      calculetteConsoScript.src = `/_components/credits/conso/calculette-${version}.js`
      calculetteConsoScript.id = 'calculette-conso-script'
      calculetteConsoScript.defer = true
      calculetteConsoScript.type = 'module'
      document.body.appendChild(calculetteConsoScript)

      return () => {
        document.body.removeChild(calculetteConsoScript)
      }
    }
  }, [location.pathname])

  return null
}

export const wrapPageElement: GatsbySSR['wrapPageElement'] = (
  { element },
  { version, pages }
) => (
  <>
    {element}
    <AddCalculetteConsoScript
      pages={pages as string[]}
      version={version as string}
    />
  </>
)
