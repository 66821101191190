import { useLocation } from '@reach/router'
import { type GatsbySSR } from 'gatsby'
import React, { type FC, useEffect } from 'react'

const AddBorrowerInsuranceSavingsSimulator: FC<{
  pages: string[]
}> = ({ pages = [] }) => {
  const location = useLocation()

  useEffect(() => {
    if (pages.includes(location.pathname)) {
      const simulatorScript = document.createElement('script')
      simulatorScript.src = `${process.env.GATSBY_BORROWER_INSURANCE_WIDGET_URL}/assets/index-v0.js`
      simulatorScript.id = 'simulateur-assurance-emprunteur-script'
      simulatorScript.defer = true
      simulatorScript.type = 'module'

      window.CREDITS_IMMO_RENEGOTIATION_INSURANCE_API_URL =
        process.env.GATSBY_BORROWER_INSURANCE_WIDGET_URL

      document.body.appendChild(simulatorScript)

      return () => {
        document.body.removeChild(simulatorScript)
      }
    }
  }, [location.pathname])

  return null
}

export const wrapPageElement: GatsbySSR['wrapPageElement'] = (
  { element },
  { pages }
) => (
  <>
    {element}
    <AddBorrowerInsuranceSavingsSimulator pages={pages as string[]} />
  </>
)
